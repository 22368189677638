import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//common services
import { MessageService } from "./message/message.service";
import { SharedService } from "./services/shared.service";
import { WebserviceService } from "./services/webservice.service";
import { CommonService } from './services/common.service';
import { LoaderService } from './services/loader.service';

// ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';

//toster
import { ToastrModule, ToastrService } from 'ngx-toastr';

//pipes
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { SearchDataPipe } from './pipes/search-data.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';

//shared components
import { LoaderComponent } from './components/loader/loader.component';
import { BetSlipComponent } from './components/bet-slip/bet-slip.component';
import { BetListComponent } from './components/bet-list/bet-list.component';
import { MarketAnalysisComponent } from './components/market-analysis/market-analysis.component';
import { ForceChangePasswordComponent } from './components/force-change-password/force-change-password.component';
import { LoginComponent } from '../auth/login/login.component';
import { MarketRulesComponent } from './components/market-rules/market-rules.component';
import { SidebarComponent } from '../layout/sidebar/sidebar.component';
import { StakeComponent } from './components/stake/stake.component';
import { TopnavComponent } from '../layout/topnav/topnav.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FooterComponent } from '../layout/footer/footer.component';
import { UpcomingComponent } from '../layout/upcoming/upcoming.component';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeURLPipe,
    SearchDataPipe,
    FormatDatePipe,
    FilterPipe,
    LoaderComponent,
    BetSlipComponent,
    BetListComponent,
    MarketAnalysisComponent,
    ForceChangePasswordComponent,
    LoginComponent,
    MarketRulesComponent,
    SidebarComponent,
    StakeComponent,
    TopnavComponent,
    FooterComponent,
    UpcomingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  exports: [
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule,
    ModalModule,
    TabsModule,
    CollapseModule,
    TooltipModule,
    TypeaheadModule,
    AccordionModule,
    SafeHtmlPipe,
    SafeURLPipe,
    SearchDataPipe,
    FormatDatePipe,
    FilterPipe,
    LoaderComponent,
    BetSlipComponent,
    BetListComponent,
    MarketAnalysisComponent,
    ForceChangePasswordComponent,
    LoginComponent,
    SidebarComponent,
    StakeComponent,
    TopnavComponent,
    FooterComponent,
    UpcomingComponent
  ],
  providers: [MessageService, SharedService, WebserviceService, CommonService, LoaderService, {
    provide: ToastrService
  }],
})
export class SharedModule { }
