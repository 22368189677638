
import { Component, Injector, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BaseClass } from 'src/app/@shared/base-class/base-class';
import { IbaseService } from 'src/app/@shared/base-service/ibase-service';
import { MessageService } from 'src/app/@shared/message/message.service';
import { CommonService } from 'src/app/@shared/services/common.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { SharedService } from 'src/app/@shared/services/shared.service';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseClass<any> implements OnInit {
  @Input() isDemoLogin: boolean = false;
  public key: any;
  public signUpPage: boolean = false;
  formGroup!: FormGroup;
  public isLoadDemo: boolean = false;
  public passType: boolean = true;
  public bsModalRef!: BsModalRef;
  modalRef?: BsModalRef;

  constructor(public service: WebserviceService,
    public fb: FormBuilder,
    public router: Router,
    private _common: CommonService,
    private _shared: SharedService,
    private injector: Injector,
    private _message: MessageService,
    protected _loader: LoaderService,
    private modalService: BsModalService
  ) {
    super(document);

    if (this.router.url.includes('signup') || this.router.url.includes('register')) {
      this.signUpPage = true;
    }
    if (this.key?.forgot || this.signUpPage == true) { this.communicateiframe(); }
  }

  ngOnInit(): void {
    this.initForm();
    if (this.isLogin()) {
      this.router.navigate([`/home`]);
    }

    // $(".psddhideshow").click(() => {
    //   alert("cfsfsfsf");
    //   var input = $('#password');      
    //   if (input.attr("type") == "password") {
    //     $(".toggle-password").removeClass("icofont-eye-open").addClass("icofont-eye-close");
    //     input.attr("type", "text");
    //   } else {
    //     $(".toggle-password").removeClass("icofont-eye-close").addClass("icofont-eye-open");
    //     input.attr("type", "password");
    //   }
    // });
  }

  ngAfterViewInit() {
    this._loader.initiateLoader();
    this._loader.hideLoader();
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  initForm() {
    this.formGroup = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [false],
    });

    if (this._common.getCookie('rd') == 'true') {
      this.formGroup.patchValue({
        username: this._common.decode_cookie(this._common.getCookie('td')),
        password: this._common.decode_cookie(this._common.getCookie('md')),
        rememberMe: true,
      })
    }
  }

  get f() {
    return this.formGroup.controls;
  }

  onLogin(key: any = '', demoReq: any = {}) {

    this.isSubmit = true;
    if (key != 'demo') {
      if (this.formGroup.invalid) {
        // if (this.f.termsCondition.errors) {
        //   this._message.errmessage("Please agree 'I Am Over 18 Years Old'");
        // }
        return;
      }
    }
    if (key == 'demo') { this.isLoadDemo = true; } else { this.isLoad = true }
    let req = {
      "username": this.formGroup.value.username,
      "password": this.formGroup.value.password,
      "domain": this.env.domain
    }

    if (this.formGroup.value.rememberMe) {
      this.setupRememberMe();
    }

    this.getServices().login(`${this.env.baseUrl + this.api.login}`, Object.keys(demoReq).length != 0 ? demoReq : req).subscribe(
      (res) => {
        if (res.status == true) {
          if (key == 'demo') { this.isLoadDemo = false; res.data.user['dr'] = true; /* dr means demo login */ } else { this.isLoad = false }
          let expiry = new Date();
          expiry.setSeconds(expiry.getSeconds() + res.data.expires_in);

          localStorage.setItem(`${this.env.panel}marquee`, res.data.message);
          localStorage.setItem(`${this.env.panel}user`, JSON.stringify(res.data.user));
          localStorage.setItem(`${this.env.panel}id_token`, `${res.data.token_type} ${res.data.access_token}`);
          localStorage.setItem(`${this.env.panel}expiry`, `${expiry}`);
          localStorage.setItem(`${this.env.panel}stakes`, JSON.stringify(res.data.stakes));

          let mn = this.changeInGameNamesMenu(res.data.event_type_settings);
          localStorage.setItem(`${this.env.panel}menu`, JSON.stringify(mn));
          localStorage.setItem(`${this.env.panel}JustL`, 'true');
          
          //popup after login
          localStorage.setItem(`${this.env.panel}popup`, 'true');

          this.closeModal();

          this._shared.emitLoginData({ isLogin: true });
          // this.router.navigate([`/dashboard`]);

        } else {
          if (key == 'demo') { this.isLoadDemo = false } else { this.isLoad = false }
          if (res.error.code == 1003) {
            this._common.checkForForceChangePassword(res);
          } else {
            this._message.errmessage(res?.error?.message);
          }
        }
      },
      (err) => {
        if (key == 'demo') { this.isLoadDemo = false } else { this.isLoad = false }
        this._message.errmessage(err?.error?.message);
      }
    );
  }

  setupRememberMe() {
    this._common.deleteCookie('rd');
    this._common.deleteCookie('td');
    this._common.deleteCookie('md');
    this._common.setCookie('rd', this.formGroup.value.rememberMe, 365);
    // console.log(this.formGroup.value.username);
    
    // console.log(this._common.encode_cookie(this.formGroup.value.username));
    
    this._common.setCookie('td', this._common.encode_cookie(this.formGroup.value.username), 365);
    this._common.setCookie('md', this._common.encode_cookie(this.formGroup.value.password), 365);
  }

  closeModal() {
    if (this.key) {
      this.bsModalRef = this.injector.get<BsModalRef>(BsModalRef);
      this.bsModalRef.hide();
    }
  }

  openModal(template: TemplateRef<any>) {
    this.closeModal();
    this.modalRef = this.modalService.show(template, { class: 'forgot_modal' });
    this.communicateiframe();
  }

  communicateiframe() {
    window.onmessage = (e) => {

      if (e.data?.status == true) {
        if (e.data?.redirect == 'home') {
          this.router.navigate(['/home']);
        }
        if (e.data?.redirect == 'login') {
          this.router.navigate(['/home'], { queryParams: { login: true } });
        } else {
          let req = {
            "username": e.data?.username,
            "password": e.data?.password,
            "domain": this.env.domain
          }
          this.onLogin('demo', req);
        }
      }
    };
  }

  loginWIthDemoId() {
    let req = {
      "username": 'Demo123',
      "password": '123456',
      "domain": this.env.domain
    }
    this.onLogin('demo', req);
  }

  togglePasscode() {
    
    // this.passType = !this.passType;
    // let doc = document.getElementById('password') as HTMLInputElement;
    // doc.type = this.passType ? 'password' : 'text';

    var input = $('#password');      
    if (input.attr("type") == "password") {
      $(".toggle-password").removeClass("icofont-eye-open").addClass("icofont-eye-blocked");
      input.attr("type", "text");
    } else {
      $(".toggle-password").removeClass("icofont-eye-blocked").addClass("icofont-eye-open");
      input.attr("type", "password");
    }
  }

  ngDoCheck() {
    this.env.trafficTag = this.router.url.includes('?') ? `?${this.router.url.split('?')[1]}` : '';
  }

}