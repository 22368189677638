export const environment = {
  production: false,
  panel: 'mango_',
  domain: "2023exch.com",
  baseUrl: "https://api.2023exch.com",
  trafficTag: '',
  pollers: {
    oddsData: 600,
    listOddsData: 10000,
    scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  isMobile: true,
  isBetOpenEnv: false,
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  desktopStyles: [],
  mobileStyles: [],
  host: {
    domain: '',
    name: '',
    dName: '',
    ext: '',
    theme: '',
    email: '',
    demoLogin: false,
    ftLivetvData: '',
    iframeDomainUrl: '',
    signUpActive: false,
    depoWithdraw: false,
    pusher: {
      key: '',
      cluster: ''
    },
    mThemePath: [''],
    dThemePath: [''],
  },
  hosts: [
    {
      domain: 'localhost',
      name: 'localhost',
      dName: 'LocalHost',
      ext: '.com',
      theme: 'blue-body',
      email: 'contactmybetbhai@gmail.com',
      demoLogin: true,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      iframeDomainUrl: 'https://reddypanel.com/',
      signUpActive: true,
      depoWithdraw: true,
      pusher: {
        key: '40928c154aa1ed0c1c8d',
        cluster: 'ap2'
      },
      mThemePath: [],
      dThemePath: [],
    },
    {
      domain: 'mango777.win',
      name: 'mango777.win',
      dName: 'mango777.win',
      ext: '.win',
      theme: 'blue-body',
      email: 'contactmybetbhai@gmail.com',
      demoLogin: true,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      iframeDomainUrl: 'https://77panel.io/',
      signUpActive: true,
      depoWithdraw: true,
      pusher: {
        key: '4ae8dfadca375e6d7f9f',
        cluster: 'eu'
      },
      mThemePath: [],
      dThemePath: [],
    },
    {
      domain: 'mango777.io',
      name: 'mango777.io',
      dName: 'mango777.io',
      ext: '.io',
      theme: 'blue-body',
      email: 'contactmybetbhai@gmail.com',
      demoLogin: true,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      iframeDomainUrl: 'https://77panel.io/',
      signUpActive: true,
      depoWithdraw: true,
      pusher: {
        key: '4ae8dfadca375e6d7f9f',
        cluster: 'eu'
      },
      mThemePath: [],
      dThemePath: [],
    },
  ],
};
