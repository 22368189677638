<div class="modal-body" *ngIf="!isDemoLogin && key?.isLogin">
   <button type="button" class="close" (click)="closeModal()">
      <img class="img-fluid" src="assets/images/delete.png" style="width: 14px; float: right; cursor:pointer">
   </button>

   <h2>login</h2>
   <p>welcome!</p>
   <form [formGroup]="formGroup" (ngSubmit)="onLogin()">
     <div class="form-group">
       <label for="username">username / number</label>
       <input type="email" formControlName="username" class="form-control" id="username" aria-describedby="emailHelp" placeholder="Enter username">
       <small *ngIf="f.username.touched && f.username.errors?.required" class="error">Required</small>
     </div>
     <div class="form-group">
       <label for="password">Password</label>
       <input formControlName="password" type="password" class="form-control" id="password" placeholder="Password">
       <span class="float-right login-password-div-span-i psddhideshow" (click)="togglePasscode()"><i class="icofont-eye-blocked toggle-password"></i></span>
       <small *ngIf="f.password.touched && f.password.errors?.required" class="error">Required</small>
     </div>
     <div class="form-group form-check">
       <input type="checkbox" formControlName="rememberMe" class="form-check-input" id="years">
       <label class="form-check-label" for="years">Remember Me</label>
     </div>
     <!-- <div class="form-group form-check">
       <input type="checkbox" class="form-check-input" id="rememeber">
       <label class="form-check-label" for="rememeber">remember me</label>
     </div> -->
     <button type="submit" class="btn btn-login" [disabled]="formGroup.invalid || isLoad">login &nbsp; &Tab; <i *ngIf="isLoad" class="icofont-spinner"></i></button>
     <a href="javascript:void(0)" (click)="openModal(forgotPasswordModal)" class="fp-link"><i class="icofont-lock"></i> Forgot password?</a>
   </form>
 </div>
 <div class="modal-footer" *ngIf="!isDemoLogin && key?.isLogin">
   <a href="mailto:mango777online@gmail.com" class="login-mail">
       <img class="img-fluid" src="assets/images/icon/png/login-mail.png">
       mango777online@gmail.com
   </a>
   <a href="https://wa.me/447478877777" target="_blank" class="login-whatsapp">
     <img class="img-fluid" src="assets/images/icon/png/login-phone.png">
     +44 74788 77777
   </a>
 </div>

 <a href="javascript:void(0)" [ngClass]="{'disabled' : isLoadDemo}" (click)="loginWIthDemoId()" *ngIf="isDemoLogin">demo <i *ngIf="isLoadDemo" class="icofont-spinner"></i></a>

<!-- signup iframe -->
 <iframe *ngIf="key?.signUpModal || signUpPage" class="embed-responsive-item sign-up-page" scrolling="yes" [src]="env.host.iframeDomainUrl + 'signup/' + env.domain + env.trafficTag | safeURL" allowfullscreen="true" ></iframe>
<!-- signup iframe -->


<!-- Forgot Password -->
<ng-template #forgotPasswordModal>
  <div class="modal-body">
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <iframe class="embed-responsive-item" scrolling="yes" [src]="env.host.iframeDomainUrl+ 'forgot-password/' +env.domain | safeURL" allowfullscreen="true" ></iframe>
  </div>
</ng-template>
<!-- /Forgot Password -->
