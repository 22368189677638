import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IbaseService } from 'src/app/@shared/base-service/ibase-service';
import { api } from 'src/app/@shared/services/api';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralConfigService {
  public api = api;
  public env = environment;
  public version!: string;
  public apiEndpointSomeData!: string;

  constructor(private router: Router, private loc: Location) { }

  load(): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.env.isMobile = (window.navigator.userAgent.includes('Mobile')) ? true : false;
      let url = this.loc.path();
      url = (url == '') ? '/login' : url;

      // if (this.env.isMobile && !(url.includes('/m/'))) {
      //   this.router.navigate([`/m${url}`])
      // } else if (!this.env.isMobile) {
      //   this.router.navigate([`${url.replace('/m/', '/')}`])
      // }
      resolve(this.env.isMobile)
    });

    return promise;
  }
}

