<div class="user-section py-2">
   <h3 class="us_title">Change Password</h3>
   <div class="row justify-content-center">
      <div class="col-md-12">
         <div class="row justify-content-center cp-light mx-0">
            <form class="cp-form col-md-6" [formGroup]="formData" (ngSubmit)="onSubmit()">
               <div class="form-group row">
                  <label class="col-md-4 col-form-label">old password</label>
                  <div class="col-md-8 position-relative">
                     <input [type]="oldPasswordTextType ? 'text' : 'password'" class="form-control"
                        formControlName="old_password" [ngClass]="{ '': submitted && f.old_password.errors }">
                     <i class="" (click)="toggleOldPasswordFieldTextType()"
                        [ngClass]="{'icofont-eye': !oldPasswordTextType, 'icofont-eye-blocked': oldPasswordTextType }"></i>
                     <div *ngIf="submitted && f.old_password.errors" class="">
                        <div *ngIf="f.old_password.errors.required">The Old Password field is required</div>
                     </div>
                  </div>

               </div>
               <div class="form-group row">
                  <label class="col-md-4 col-form-label">new Password</label>
                  <div class="col-md-8 position-relative">
                     <input [type]="newPasswordTextType ? 'text' : 'password'" class="form-control"
                        formControlName="new_password" [ngClass]="{ '': submitted && f.new_password.errors }">
                     <i class="" (click)="toggleNewPasswordFieldTextType()"
                        [ngClass]="{'icofont-eye': !newPasswordTextType, 'icofont-eye-blocked': newPasswordTextType}"></i>
                     <div *ngIf="submitted && f.new_password.errors" class="">
                        <div *ngIf="f.new_password.errors.required">The New Password field is required</div>
                        <div *ngIf="f.new_password.errors.minlength">The New Password field must be at least
                           6 characters
                        </div>
                        <div *ngIf="!f.new_password.errors.minlength && f.new_password.errors.pattern">
                           The New Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number
                        </div>
                     </div>
                  </div>
               </div>
               <div class="form-group row">
                  <label class="col-md-4 col-form-label">confirm Password</label>
                  <div class="col-md-8 position-relative">
                     <input [type]="confirmPasswordTextType ? 'text' : 'password'" class="form-control"
                        formControlName="new_password_re" [ngClass]="{ '': submitted && f.new_password_re.errors }">
                     <i class="" (click)="toggleConfirmPasswordFieldTextType()"
                        [ngClass]="{ 'icofont-eye': !confirmPasswordTextType, 'icofont-eye-blocked': confirmPasswordTextType }"></i>
                     <div *ngIf="submitted && f.new_password_re.errors" class="">
                        <div *ngIf="f.new_password_re.errors.required">
                           The Confirm Password field is required
                        </div>
                        <div *ngIf="f.new_password_re.errors.mustMatch">
                           The Confirm Password confirmation does not match
                        </div>
                     </div>
                  </div>
               </div>
               <div class="form-group row justify-content-center">
                  <div class="col-md-4">
                     <button type="submit" class="btn btn-submit">submit</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>